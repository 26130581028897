let resumeData = {
  "imagebaseurl":"https://github.com/blanfried",
  "name": "Baer Lanfried",
  "number": "Send Me a Message",
  "contactLink": "https://www.baerlanfriedblog.com/contact/",
  "primaryEmail": "blanfried@gmail.com",
  "secondaryEmail": "blanfried@yahoo.com",
  "role": "Philosopher | Entrepreneur | Website Developer | Writer | Editor |",
  "linkedinId":"https://www.linkedin.com/in/baer-lanfried-488ab61aa/",
  "roleDescription": " This webpage was created by, and is devoted to, moi-même (myself). *Last Updated: June 2024",
  "socialLinks":[
      {
        "name":"linkedin",
        "url":"https://www.linkedin.com/in/baer-lanfried-488ab61aa/",
        "className":"fab fa-linkedin"
      },
      {
        "name":"github",
        "url":"http://github.com/blanfried",
        "className":"fab fa-github"
      },
      {
        "name":"Youtube",
        "url":"https://www.youtube.com/@BaerLanfried",
        "className":"fab fa-youtube"
      },
      {
        "name":"Facebook",
        "url":"https://www.facebook.com/baer.lanfried",
        "className":"fab fa-facebook"
      },
      // {
      //   "name":"Instagram",
      //   "url":"https://www.instagram.com/baerlanfried/",
      //   "className":"fab fa-instagram"
      // },
      {
        "name":"Medium",
        "url":"https://baerlanfried.medium.com/",
        "className":"fab fa-medium"
      },
      {
        "name":"Substack",
        "url":"https://abitofme.substack.com/",
        "className":"fa-solid fa-bookmark"
      },
      {
        "name":"TikTok",
        "url":"hhttps://www.tiktok.com/@baerlanfried",
        "className":"fab fa-tiktok"
      }
    ],
  "aboutme":"I grew up in Southern California but have spent the majority of my adult life living in France and the United Kingdom. These days, I live semi-nomadically but principally between California (with my family) and Wales (with my girlfriend). I'm building my business (Genyst.com) and online presence as a writer and tutor. Professional languages: English | French | Spanish",
  "address":"PO Box 580, Pauma Valley, CA 92061, USA",
  "education":[
    {
      "UniversityName":"University of Birmingham (UK)",
      "specialization":"Ethics & Philosophy of Religion",
      "MonthOfPassing":"December",
      "YearOfPassing":"2022",
      "Achievements":"Master's (MA)"
    },
    {
      "UniversityName":"University of Birmingham (UK)",
      "specialization":"Full-Stack Coding Bootcamp",
      "MonthOfPassing":"December",
      "YearOfPassing":"2022",
      "Achievements":"Certification"
    },
    {
      "UniversityName":"American University of Paris",
      "specialization":"Philosophy",
      "MonthOfPassing":"May",
      "YearOfPassing":"2020",
      "Achievements":"Bachelor's (BA)"
    },
    {
      "UniversityName":"American University of Paris",
      "specialization":"Psychology",
      "MonthOfPassing":"May",
      "YearOfPassing":"2020",
      "Achievements":"Bachelor's (BA)"
    },
    {
      "UniversityName":"International TEFL Training Institute",
      "specialization":"Teaching English as a Foreign Language",
      "MonthOfPassing":"June",
      "YearOfPassing":"2020",
      "Achievements":"Certification"
    }
  ],
  "work":[
    {
      "CompanyName":"Entrepreneur",
      "specialization":"Genyst.com",
      "MonthOfLeaving":"September 2023 -",
      "YearOfLeaving":"Present",
      "Achievements":"I founded a custom-branded website development agency dedicated to empowering individuals and small businesses to thrive in competition with larger corporations. Our approach is straightforward, leveraging a simple process that ensures success."
    },
    {
      "CompanyName":"Tutor (Online and in Person)",
      "specialization":"Philosophy, English, Writing, French, & Spanish",
      "MonthOfLeaving":"January 2019 -",
      "YearOfLeaving":"Present",
      "Achievements":"With over 1000 hours of tutoring experience amassed over the last 5 years, I now primarily offer my services online through various platforms in both the US and UK. While my recent endeavors have concentrated on Philosophy and Writing, my extensive tutoring history also includes English, along with some French and Spanish."
    },
    {
      "CompanyName":"Freelance Web Developer",
      "specialization":"Full-Stack and CMS",
      "MonthOfLeaving":"June 2022 -",
      "YearOfLeaving":"Present",
      "Achievements":"Contracted for website creation from scratch. I primarily use static site generators, like Hugo, and CMS tools, like Shopify. I have created/managed websites for a Chamber of Commerce in San Diego, CA as well as Interior Design, Agritourism Farm, Demolition, Construction, and Vacation Rental companies."
    },
    {
      "CompanyName":"Baer (Youtube)",
      "specialization":"Creator/Travel Vlogger",
      "MonthOfLeaving":"August 2020 -",
      "YearOfLeaving":"Present",
      "Achievements":"Nearly 200k views and 50,000 hours watched on my YouTube videos (on and off my channel)."
    },
    {
      "CompanyName":"Mysterious Bookshop",
      "specialization":"Part-Time Bookseller",
      "MonthOfLeaving":"Jan 2023 -",
      "YearOfLeaving":"April 2023",
      "Achievements":"A fun part-time job that I worked on Saturdays whilst living in NYC. I sold books, made recommendations, re-shelved/organized, worked cash register and dealt with customers effectively and professionally."
    },
    {
      "CompanyName":"Freelance Editor and English Teacher in Biarritz, France",
      "specialization":"Full-Time Private Editor & English Teacher",
      "MonthOfLeaving":"August 2020 -",
      "YearOfLeaving":"August 2022",
      "Achievements":"Hired to proofread, edit, and help write certain professional assignments (presentations, reports, articles, etc.). Privately taught English one-on-one and in a classroom setting to adults of all different levels and nationalities whilst living in Biarritz, France."
    },
    {
      "CompanyName":"The American University of Paris",
      "specialization":"University Writing Lab Tutor",
      "MonthOfLeaving":"September 2019 -",
      "YearOfLeaving":"May 2020",
      "Achievements":"Worked at my university's Writing Lab, editing and proofreading academic papers. I provided feedback and guided fellow students to better writing techniques."
    },
    {
      "CompanyName":"Paris Atlantic Magazine",
      "specialization":"Published",
      "MonthOfLeaving":"September 2019 -",
      "YearOfLeaving":"May 2020",
      "Achievements":"Published my short story \"La Palette\" in the 2020 issue of Paris Atlantic Magazine."
    },
    {
      "CompanyName":"“Let’s Talk About God” Club and Radio Show",
      "specialization":"President/Founder",
      "MonthOfLeaving":"September 2019 -",
      "YearOfLeaving":"May 2020",
      "Achievements":"Creator and host of a radio show which broadcasted to all of Paris and the French Riviera. Organized and mediated oftentimes tense discussions surrounding deeply held beliefs in both club and show. Used charisma and creativity to keep conversation/debate lively and interesting."
    },
    {
      "CompanyName":"The American University of Paris",
      "specialization":"Student Advisor",
      "MonthOfLeaving":"August 2019 -",
      "YearOfLeaving":"December 2019",
      "Achievements":"Advisor for incoming fall transfer students."
    },
    {
      "CompanyName":"The American University of Paris",
      "specialization":"Student Librarian",
      "MonthOfLeaving":"September 2018 -",
      "YearOfLeaving":"May 2019",
      "Achievements":"Aided students and staff in research and finding books/materials. Organized library books/materials."
    },
    {
      "CompanyName":"Maxwell-Baynes, Christie's International Real Estate",
      "specialization":"Intern/Shadow",
      "MonthOfLeaving":"May 2018 -",
      "YearOfLeaving":"July 2018",
      "Achievements":"I shadowed my boss and co-owner of the company, Michael Baynes, and learned how he dealt with clients and communicated effectively in order to sell chateaus and vineyards. Also aided in planning and communication between team and clients."
    },
    {
      "CompanyName":"Cucina Enoteca",
      "specialization":"Busser/Polisher",
      "MonthOfLeaving":"April 2016 -",
      "YearOfLeaving":"August 2016",
      "Achievements":"Bussed and Polished at a high-end Italian Restaurant in Newport Beach, CA."
    },
    {
      "CompanyName":"City of Newport Beach",
      "specialization":"Lifeguard",
      "MonthOfLeaving":"March 2014 -",
      "YearOfLeaving":"September 2014",
      "Achievements":"Beach lifeguard trainee and first year cadet for city of Newport Beach Fire Department Marine Operations Division. Pool Lifeguard for the remainder of that summer and for a bit into that school year."
    }
  ],
  
  "skills":[
    // If add a skill, change corresponding css class in layout under skill bars
    {
      "skillname":"Interpersonal"
    },
    {
      "skillname":"Problem-Solving"
    },
    {
      "skillname":"Web-Development"
    },
    {
      "skillname":"Writing"
    },
    {
      "skillname":"Editing"
    },
    {
      "skillname":"Video-Editing"
    }
  ],
  // Photos need to be 1280 x 720. Otherwise it will not look as clean.
  "portfolio":[
    {
      "name":"Genyst.com",
      "description":"My Website Development Business",
      "imgurl":"./images/portfolio/Genyst.png",
      "url":"https://www.Genyst.com/"
    },
    {
      "name":"Chamber of Commerce (Valley Center, CA)",
      "description":"Lead Developer on Valley Center's Chamber of Commerce Website",
      "imgurl":"./images/portfolio/VCChamber.png",
      "url":"https://vcchamber.com"
    },
    {
      "name":"Effortless Demolition",
      "description":"Sole Developer on Effortless Demolition's Company Website",
      "imgurl":"./images/portfolio/EffortlessDemo.png",
      "url":"https://effortlessdemolition.com"
    },
    {
      "name":"Quixote Farm",
      "description":"Lead Developer on Quixote Farm's E-Commerce Website",
      "imgurl":"./images/portfolio/QuixoteFarm.png",
      "url": "https://www.quixotefarm.com"
    },
    {
      "name":"Baer Blog",
      "description":"My Blog Site (More Posts Coming Soon)",
      "imgurl":"./images/portfolio/BlogSite.png",
      "url": "https://www.baerlanfriedblog.com"
    },
    {
      "name":"Baer",
      "description":"My Youtube Channel",
      "imgurl":"./images/portfolio/BaerYoutubeProfPic.png",
      "url":"https://www.youtube.com/@BaerLanfried"
    },
    {
      "name":"Tutoring Profile",
      "description":"My Wyzant Tutoring Profile (as of May 24, 2024)",
      "imgurl":"./images/portfolio/WyzantProf.png",
      "url": "https://www.wyzant.com/Tutors/ateachingBaer"
    },
    {
      "name":"Academia Profile",
      "description":"My Academic Writing",
      "imgurl":"./images/portfolio/AcademiaPic.png",
      "url": "https://birmingham.academia.edu/BaerLanfried"
    }


    // Decided I didn't really like this website
    // {
    //   "name":"Escape Properties",
    //   "description":"Simple Vacation Home Rental Website. *Sole Developer",
    //   "imgurl":"./images/EscapeProperties.png",
    //   "url": "https://www.escapepropertiesgroup.com"
    // },
    // {
    //   "name":"Fakebook",
    //   "description":"MERN Stack Facebook Clone *Collaboration",
    //   "imgurl":"./images/FakebookPic.png",
    //   "url":"https://agile-taiga-04390.herokuapp.com"
    // },
    // {
    //   "name":"SCA Car Rental Website",
    //   "description":"Full-Stack Car Rental Website *Project Lead",
    //   "imgurl":"./images/portfolio/SCARentalpic.png",
    //   "url": "https://github.com/blanfried/SCA-Rental"
    // }
    // {
    //   "name":"Note Taker",
    //   "description":"Note-Taking Full-Stack Application *Sole Developer",
    //   "imgurl":"./images/NoteTakerPic.png",
    //   "url":"https://github.com/blanfried/Notes_App"
    // }
    // {
    //   "name":"Social Network API",
    //   "description":"Solo JavaScript Backend Project",
    //   "imgurl":"./images/SocialNetworkAPIPic.png",
    //   "url":"https://github.com/blanfried/Social-Network-API"
    // },
    // {
    //   "name":"Lyric Finder Website",
    //   "description":"Bootcamp Project Lead On A Front-End Lyric Finder Website",
    //   "imgurl":"./images/LyricsFinderPic.png",
    //   "url":"https://github.com/blanfried/Lyric-Finder"
    // },
  ],
    "testimonials":[
    {
      "description":"Excellent Philosophy and Debate Guidance! | \"I reached out to Baer to tutor me on Debate and Philosophy so that I could better structure my own thinking, writing, and speaking. He delivers! Baer is a terrifically clear thinker with a broad knowledge base in all elements of rhetoric and debate. He is very friendly and clearly passionate about the subjects - his passion is contagious. He is fastidious in his meeting summaries and far reaching in his curriculum planning. Whether you are just dipping your toes in philosophy or already a seasoned student, I'd highly recommend you work with Baer each week, it's time and money well spent!\"",
      "name":"Wyzant review from Max on 2/22/2024"
    },
    {
      "description":"Thorough and Patient | \"Baer and I reviewed Kantianism (deontology), utilitarianism, and social contract theory and covered examples of each theory. Baer was thorough with his explanations and did a really good job at making sure I understood them for a midterm I had coming up. Super appreciative of his time and help.\"",
      "name":"Wyzant review from Andrew on  2/26/2024"
    },  
    {
      "description":"\"Mr. Lanfried would be amongst the top percentile of interns that we have experienced, not just because of his skill set, but above all due to his personality, reliability and willingness to do whatever it takes in a team environment. He would be an asset to any employer, and I have no hesitation to recommend him for any endeavor he chooses to pursue.\"",
      "name":"Michael Baynes (Executive Partner of Maxwell-Baynes, Christie's International Real Estate)"
    },
    {
      "description":"\"[Baer] proved himself to be an extremely reliable and diligent participant, and produced work of excellent quality. He never missed a class, never came late or unprepared, never missed a deadline, and always had completed the heavy readings and other homework…I can wholeheartedly recommend Baer Lanfried—I would love to have him as a colleague or, when he is ready for such a role, as a supervisor.\"",
      "name":"Dr. habil. Jula Wildberger (Professor of Classics and Comparative Literature at The American University of Paris)"
    },
    {
      "description":"\"I have had the pleasure of working side by side with Baer. As part of our select team of highly qualified student writers, his working relationship with his academic peers was marked by his sincere interest in each student and his sensitivity to their immediate writing needs; additionally, Baer’s professionalism as a worker and a team player was exemplary.\"",
      "name":"Ann Mott (Professor, Department of Comparative Literature and English Director, Writing Lab)"
    },
    {
      "description":"\"Baer [is] an interesting, studious, and dedicated student with great communication skills. As an assistant he was very well organized, diligent and was able work independently. His personality and communication skills were much appreciated at our University Library. All these skills are very valuable, and that is why I allow myself to recommend Baer Lanfried. I am confident that he will undertake his new challenges with enthusiasm and rigor.\"",
      "name":"Jorge Sosa, MSLS (University Librarian / Directeur de la Bibliothèque Universitaire at The American University of Paris)"
    },
    {
      "description":"\"[Baer] displays confidence without any sense of arrogance and, in my estimation, is a humble man who listens and learns. I believe that he would prove to be an asset to any organization that he would align himself with.\"",
      "name":"Mark Shaffer (Assistant General Manager at KWVE Radio)"
    },
    {
      "description":"5 Stars",
      "name":"Wyzant Tutoring Platform Rating"
    },
    {
      "description":"Global Professional Skills Honour",
      "name":"The American University of Paris"
    },
    {
      "description":"Provost's List (2020)",
      "name":"The American University of Paris"
    },
    {
      "description":"Provost's List (2019)",
      "name":"The American University of Paris"
    }
  ]
}

export default resumeData